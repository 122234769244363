<script setup lang="ts">
import IconSvgBase from './IconSvgBase.vue';

const R = 11;
const STROKE_WIDTH = 4;
const VIEW_SIZE = 2 * (R + STROKE_WIDTH / 2);
const CIRCLE_CENTER = VIEW_SIZE / 2;

const STYLE_BASE = {
  '--r': `${R}px`,
};
</script>

<template>
  <IconSvgBase
    :view-width="VIEW_SIZE"
    :view-height="VIEW_SIZE"
    :class="$style.icon"
    :style="STYLE_BASE"
    role="status"
  >
    <circle
      :class="$style.icon__circle"
      :cx="CIRCLE_CENTER"
      :cy="CIRCLE_CENTER"
      :r="R"
      :stroke-width="STROKE_WIDTH"
    />
  </IconSvgBase>
</template>

<style lang="scss" module>
$pi: 3.14159;

.icon {
  width: 1.4em;
  height: 1.4em;

  &__circle {
    stroke: $color-text-and-object-accent-primary;
    stroke-dasharray: calc(var(--r) * #{math.div(2 * $pi * 3, 4)}), 9999;
    stroke-linecap: round;
    fill: transparent;
    animation: icon-spinner-animation 800ms linear infinite;
    transform-origin: 50% 50%;
  }
}

@keyframes icon-spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
